import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { AdminService } from "../../services/admin.service";
import { LoadingController, ModalController, NavParams } from "@ionic/angular";
import { RouteService } from "src/app/services/route.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { AddRouteCustomerPage } from "./add-route-customer/add-route-customer.page";
@Component({
  selector: "app-modal-add-items",
  templateUrl: "./modal-add-items.page.html",
  styleUrls: ["./modal-add-items.page.scss"],
})
export class ModalAddItemsPage implements OnInit {
  type: string;
  isItemAvailable = false;
  companyid;
  routeid;
  nextOrderNumber: number = 0;
  isUpdated: boolean;
  updatedata: any = {};
  routeItem: any = {};
  jobData: any = {};
  noteData: any = {};
  customers: any[] = [];
  filteredCustomers: any = [];
  customerRequiredError = false;
  item: any = {
    job: {
      customer: {
        id: "",
        firstName: "",
        lastName: "",
      },
      notes: "",
    },
    note: {
      note: "",
    },
  };
  itemid;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "190px",
    minHeight: "0",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [
        "subscript",
        "superscript",
        "redo",
        "italic",
        "justifyFull",
        ,
        "heading",
        "indent",
      ],
      ["fontSize", "customClasses", "insertVideo"],
    ],
  };
  isUpdate: boolean = false;
  searchText: any = "";
  constructor(
    private loadingCtrl: LoadingController,
    public user: UserService,
    private admin: AdminService,
    private routeService: RouteService,
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.routeid = this.navParams.get("route");
    this.nextOrderNumber = (1 + this.navParams.get("items")) * 10;
    this.item.order = this.nextOrderNumber;
    if (typeof this.navParams.get("itemdata") != "undefined") {
      this.isUpdate = true;
      this.item = this.navParams.get("itemdata");

      this.itemid = this.item.id;
      this.type = this.item.type;
    }

    this.getCustomer(this.user.userInfo.user.company.id);
    this.companyid = this.user.userInfo.user.company.id;
  }
  addFocus() {}
  removeFocus() {}
  // Declare the variable (in this case isItemAvailable)
  getCustomers(event) {
    this.searchText = event.target.value;
    let name;
    let custstatus;
    if (this.searchText != "") {
      this.filteredCustomers = this.customers.filter((cust) => {
        name = cust.firstName + " " + cust.lastName;
        custstatus = "active";

        return (
          name
            .toString()
            .toLowerCase()
            .includes(this.searchText.toString().toLowerCase()) &&
          cust.status.toLowerCase() == "active"
        );
      });
      if (this.filteredCustomers.length > 0) {
        this.isItemAvailable = true;
      }
    } else if (this.searchText == "") {
      this.filteredCustomers = [];
    }
  }

  addCustomer(customer) {
    this.searchText = customer.firstName + " " + customer.lastName;
    this.item.job.customer.id = customer.id;
    this.filteredCustomers = [];
  }

  save(form: any) {
    let itemData = form.value;

    if (form.value.id == null) this.routeItem.route = this.routeid;
    this.routeItem.order = this.nextOrderNumber; //itemData['routeOrder']
    this.routeItem.type = itemData["type"];

    if (itemData["type"] == "job") {
      if(!this.item.job.customer.id){
        this.customerRequiredError = true;
        setTimeout( ()=> this.customerRequiredError = false, 4000)
        return false
      }
      this.jobData.route = this.routeid;
      this.jobData.customer = this.item.job.customer.id;
      this.jobData.company = this.companyid;
      this.jobData.status = "active";
      this.jobData.notes = itemData["description"] || "";
      //Default Note Fields even tho they are ignored
      this.noteData = { route: this.routeid, note: "" };
      this.routeService
        .createRouteItem(this.routeItem, this.jobData, this.noteData)
        .subscribe((res) => {
          this.modalCtrl.dismiss(res["data"]);
        });
    } else {
      this.jobData.route = this.routeid;
      this.jobData.customer = "";
      this.jobData.company = this.companyid;
      this.jobData.status = "active";
      //this.routeItem.route=this.routeid;
      this.noteData.route = this.routeid;
      this.noteData.note = this.item.note.note;

      this.routeService
        .createRouteItem(this.routeItem, this.jobData, this.noteData)
        .subscribe((res) => {
          this.modalCtrl.dismiss(res["data"]);
        });
    }
  }

  update(data) {
    this.updatedata.route = this.routeid;
    this.updatedata.customer = this.item.job.customer.id;
    this.updatedata.company = this.companyid;
    this.updatedata.status = "active";
    this.updatedata.notes = this.item.job.notes;
    //this.updatedata.type=data.value['type'];

    if (this.type == "job") {
      this.routeService
        .updateJob(this.item.job.id, this.updatedata)
        .subscribe((res) => {
          this.modalCtrl.dismiss(res["data"]);
        });
    } else {
      this.routeService
        .updateJob(this.item.job.id, this.updatedata)
        .subscribe((res) => {
          //this.updatejob();
        });
    }
  }

  typeSelected(event) {
    this.type = event["detail"]["value"];
  }

  async getCustomer(companyId: string) {
    const loading = await this.loadingCtrl.create();
    loading.present();
    this.admin.getUsers(companyId).subscribe(
      (res) => {
        loading.dismiss();
        this.customers = res.data["company"]["customers"];
        this.filteredCustomers = res.data["company"]["customers"];
      },
      (err) => {
        console.log(err);
        loading.dismiss();
      }
    );
  }

  async openNewCustomer(event) {
    const modal = await this.modalCtrl.create({
      component: AddRouteCustomerPage,
    });
    modal.onDidDismiss().then((data: any) => {
      this.getCustomer(this.companyid);
      if (data.data.type === "toRoute")
        this.addCustomer(data.data.res.createCompanyCustomer);
    });
    return await modal.present();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
