import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-modal-create-customer',
  templateUrl: './modal-create-customer.page.html',
  styleUrls: ['./modal-create-customer.page.scss'],
})
export class ModalCreateCustomerPage implements OnInit {
  company
  companyid
  constructor(
    private modalCtrl: ModalController,
    private user: UserService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.companyid = this.user.userInfo.user.company.id;
  }
  addCustomer(data){
    
    data.value['company']=this.companyid;
    data.value['status'] = 'active';
    this.customerService.createCustomer(data.value).subscribe(res=>{
    
      this.modalCtrl.dismiss(res['data'])
    })
  }

  closeModal(data) {
    this.modalCtrl.dismiss(false);
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
}
