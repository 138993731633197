import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-card-item',
  templateUrl: './time-card-item.component.html',
  styleUrls: ['./time-card-item.component.scss'],
})
export class TimeCardItemComponent implements OnInit {
@Input() timecard: any
showDetails: boolean =  false;
  constructor() { }

  ngOnInit() {}
  totalHours(totalTime){
    return totalTime / 60
  }
  appendDay(onDate: Date , entryDate: Date){
    const d1 = new Date(onDate)
    const d2 = new Date(entryDate)
    const month = d2.toLocaleString('default', { month: 'short' });
    const day = d2.getDate().toString()
    if(d1.getDate() !=  d2.getDate()){
      return `(${month} ${day})`
    }
  }

}
