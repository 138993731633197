import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CopymovePageRoutingModule } from './copymove-routing.module';

import { CopymovePage } from './copymove.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CopymovePageRoutingModule
  ],
  declarations: [CopymovePage]
})
export class CopymovePageModule {}
