// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageJson from '../../package.json';


export const environment = {
  production: false,
  VERSION: packageJson.version,
  graphQL: {
  serverUri: "https://graphql.easybeezy.app",
  //serverUri: "http://localhost:4003",
  },
};
