import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { UserService } from "./services/user.service";
import { Preferences } from "@capacitor/preferences";
import packageJson from '../../package.json';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  version = 2;
  isBeta = false;


  constructor(
    public userService: UserService,
    private platform: Platform,

    public router: Router,
  
  ) {
    this.initializeApp();
    this.setBetaBoolen();
  }

  initializeApp() {
    console.log(packageJson.version, "VERSION")
    this.platform.ready().then(() => {
      this.userService.initFromStorage();
      window.addEventListener("initComplete", (ans) => {
        
        if (this.userService.isLoggedIn && !this.router.url.includes("login")  && !this.router.url.includes("signup")) {
          this.router.navigate(["/route/list"]);
        }
      });
    });
  }
 
  async logout() {
    this.userService.isLoggedIn = false;
    await Preferences.remove({key:"companyUserLogin"})
    this.router.navigate(["/login"]);
  }
  setBetaBoolen(){
    const uri = window.location.hostname.toString();
  
    this.isBeta = uri.includes('beta') || uri.includes('localhost')  ? true : false
  }
}
