import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { Router } from "@angular/router";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { RouteService } from "src/app/services/route.service";
import { AdminService } from "src/app/services/admin.service";

@Component({
  selector: "app-add-route",
  templateUrl: "./add-route.page.html",
  styleUrls: ["./add-route.page.scss"],
})
export class AddRoutePage implements OnInit {
  company;
  isRestricted: boolean = false;
  statusAlertOptions = {
    header: 'Route Status',
    subHeader: '',
    message: '',
    translucent: true,
  };
  constructor(
    private routeService: RouteService,
    private adminService: AdminService,
    private userService: UserService,
    public navParams: NavParams,
    private route: Router,
    private modalCtrl: ModalController,
    public toastController: ToastController
  ) {}
  ngOnInit() {
    const activeRoutes = this.navParams.data.activeRoutes;
    this.company = this.userService.userInfo["user"]["company"]["id"];
    const planLevel =
      this.userService.userInfo["user"]["company"]["planLevel"] || 10;
    this.isRestricted = this.adminService.checkRestrictRouteAndUser(
      activeRoutes,
      planLevel
    );
    if (this.isRestricted)
    this.statusAlertOptions.subHeader =  "Please Upgrade your company plan to allow for more active routes."
    
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
    });
    toast.present();
  }

  addRoute(data) {
    data.value["company"] = this.company;
    data.value["isTemplate"] = true;
    this.routeService.createRoute(data.value).subscribe((res) => {
      var data = res.data["createRoute"];
      this.closeModal(data);
    });
  }
  closeModal(data) {
    this.modalCtrl.dismiss(data);
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }
}
