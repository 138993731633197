import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

import { UserService } from "../../../services/user.service";
import { CustomerService } from "src/app/services/customer.service";

@Component({
  selector: "app-add-route-customer",
  templateUrl: "./add-route-customer.page.html",
  styleUrls: ["./add-route-customer.page.scss"],
})
export class AddRouteCustomerPage implements OnInit {
  customer: any = {};
  companyId: any = "";
  data: any = {};
  customerId;
  constructor(
    private modalCtrl: ModalController,
    private userSvc: UserService,
    private customerService: CustomerService,
    public navParams: NavParams
  ) {}

  ngOnInit() {
    this.customer = this.navParams.data;
    this.companyId = this.userSvc.userInfo["user"]["company"]["id"];
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  addCustomer(data) {
    data.value["company"] = this.companyId;
    data.value["status"] = "active";
    this.customerService.createCustomer(data.value).subscribe((res: any) => {
      const newCustomer = res.data.createCompanyCustomer;
      this.customerId = newCustomer.id;
      this.modalCtrl.dismiss({ res: res["data"], type: "save" });
    });
  }

  addCustomerToRoute(data) {
    
    data.value["company"] = this.companyId;
    data.value["status"] = "active";
    this.customerService.createCustomer(data.value).subscribe((res: any) => {
      const newCustomer = res.data.createCompanyCustomer;
      this.customerId = newCustomer.id;
      this.modalCtrl.dismiss({ res: res["data"], type: "toRoute" });
    });
  }

  closeModal(data = {}) {
    this.modalCtrl.dismiss();
  }
}
