import { Component, OnInit, Optional, Inject } from "@angular/core";
import { LoadingController, ModalController, NavParams, ToastController } from "@ionic/angular";
import { AdminService } from "../../../services/admin.service";
import { UserService } from "../../../services/user.service";
import { RouteService } from "../../../services/route.service";
import { ServiceItemService } from "../../../services/serviceItem.service";

@Component({
  selector: "app-add-service",
  templateUrl: "./add-service.page.html",
  styleUrls: ["./add-service.page.scss"],
})
export class AddServicePage implements OnInit {
  service: any = {};
  company: any = "";
  isRestrict: boolean = false;
  isUpdated: boolean = false;
  serviceItem: any = { status: "active" };

  constructor(
    private serviceItemService: ServiceItemService,
    private adminService: AdminService,
    private modalCtlr: ModalController,
    private loadingCtlr: LoadingController,
    public navParams: NavParams,
    public userService: UserService,
    public toastController: ToastController
  ) {}

  ngOnInit() {
 
    this.isUpdated = this.navParams.data.isUpdated;
    this.serviceItem = this.navParams.get("serviceItem");
    this.company = this.userService.userInfo.user.company.id;
    if (this.serviceItem != undefined) {
      this.isUpdated = true;
    } else {
      this.serviceItem = { status: "active" };
      this.isUpdated = false;
    }
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
    });
    toast.present();
  }

  async editService(form) {
    //this.closeModal(this.route)
    const lc = await this.loadingCtlr.create({message:"saving..."})
    lc.present();
    let data = form.value;
    data["company"] = this.company;

    data["id"] = this.serviceItem["id"];
    data["abbreviation"] = data.name

    this.serviceItemService
      .updateServiceItem(this.serviceItem["id"], data)
      .subscribe((res) => {
        lc.dismiss();
        var data = res.data["updateServiceItem"];
        this.presentToast(data.msg);
        this.modalCtlr.dismiss(data.result);
        //this.closeModal(data)
      });
  }

  async addService(form) {
    const lc = await this.loadingCtlr.create({message:"saving..."})
    lc.present();
    let data = form.value;
    data["company"] = this.company;
    data["abbreviation"] = data.name
    this.serviceItemService.addService(data).subscribe((res) => {
      lc.dismiss();
      this.modalCtlr.dismiss(data);
    });
  }

  closeModal() {
    this.modalCtlr.dismiss();
  }
}
