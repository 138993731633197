import { CustomerListPage } from "./pages/customers/customer-list/customer-list.page";

import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { Loginauthservice } from "./services/loginauth.service";
import { AdminGuard } from "./middleware/admin.guard";
import { SilverGuard } from "./middleware/silver.guard";
import { GoldGuard } from "./middleware/gold.guard";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "route/list",
    loadChildren: () =>
      import("./pages/route/list/list.module").then((m) => m.ListPageModule),
    canActivate: [Loginauthservice],
  },
  {
    path: "route/:id",
    loadChildren: () =>
      import("./pages/route/detail/detail.module").then(
        (m) => m.DetailPageModule
      ),
    canActivate: [Loginauthservice],
  },
  {
    path: "customers",
    loadChildren: () =>
      import("./pages/customers/customer-list/customer-list.module").then(
        (m) => m.CustomerListPageModule
      ),
    canActivate: [AdminGuard],
  },
  // {
  //   path: 'customers/:id',
  //   loadChildren: () => import('./pages/customers/add-customer/add-customer.module').then(m => m.AddCustomerPageModule),
  //   canActivate:[Loginauthservice]
  // },
  {
    path: "route/:routeId/job/:jobId",
    loadChildren: () =>
      import("./pages/job/detail/detail.module").then(
        (m) => m.DetailPageModule
      ),
    canActivate: [Loginauthservice],
  },
  {
    path: "users",
    loadChildren: () =>
      import("./pages/users/users.module").then((m) => m.UsersPageModule),
    canActivate: [AdminGuard],
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./pages/settings/settings.module").then(
        (m) => m.SettingsPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: "timesheet",
    loadChildren: () =>
      import("./pages/timesheet/timesheet.module").then(
        (m) => m.TimesheetPageModule
      ),
    canActivate: [AdminGuard],
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./pages/reports/reports.module").then((m) => m.ReportsPageModule),
    canActivate: [SilverGuard],
  },
  {
    path: "archive",
    loadChildren: () =>
      import("./pages/archive/archive.module").then((m) => m.ArchivePageModule),
    canActivate: [AdminGuard],
  },
  {
    path: "customers/edit",
    loadChildren: () =>
      import("./pages/customers/edit-customer/edit-customer.module").then(
        (m) => m.EditCustomerPageModule
      ),
  },

  {
    path: "task",
    loadChildren: () =>
      import("./pages/job/modals/task/task.module").then(
        (m) => m.TaskPageModule
      ),
  },
  {
    path: "job-note",
    loadChildren: () =>
      import("./pages/job/modals/job-note/job-note.module").then(
        (m) => m.JobNotePageModule
      ),
  },
  {
    path: "photo-view",
    loadChildren: () =>
      import("./pages/job/modals/photo-view/photo-view.module").then(
        (m) => m.PhotoViewPageModule
      ),
  },
  {
    path: "cancel",
    loadChildren: () =>
      import("./pages/job/modals/cancel/cancel.module").then(
        (m) => m.CancelPageModule
      ),
  },
  {
    path: "checkin",
    loadChildren: () =>
      import("./pages/job/modals/checkin/checkin.module").then(
        (m) => m.CheckinPageModule
      ),
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./pages/job/modals/checkout/checkout.module").then(
        (m) => m.CheckoutPageModule
      ),
  },
  {
    path: "hour",
    loadChildren: () =>
      import("./pages/job/modals/hour/hour.module").then(
        (m) => m.HourPageModule
      ),
  },
  {
    path: "edit",
    loadChildren: () =>
      import("./pages/route/edit/edit.module").then((m) => m.EditPageModule),
  },
  {
    path: "confirmation",
    loadChildren: () =>
      import("./pages/modals/confirmation/confirmation.module").then(
        (m) => m.ConfirmationPageModule
      ),
  },
  {
    path: "edit-note",
    loadChildren: () =>
      import("./pages/modals/edit-note/edit-note.module").then(
        (m) => m.EditNotePageModule
      ),
  },

  {
    path: "edit-job",
    loadChildren: () =>
      import("./pages/job/modals/edit-job/edit-job.module").then(
        (m) => m.EditJobPageModule
      ),
  },
  {
    path: "copymove",
    loadChildren: () =>
      import("./pages/route/modals/copymove/copymove.module").then(
        (m) => m.CopymovePageModule
      ),
  },
  {
    path: "equipment",
    loadChildren: () =>
      import("./pages/equipment/equipment.module").then(
        (m) => m.EquipmentPageModule
      ),
  },
  {
    path: "admin/customer-import",
    loadChildren: () =>
      import("./pages/admin/customer-import/customer-import.module").then(
        (m) => m.CustomerImportPageModule
      ),
    canActivate: [SilverGuard],
  },
  {
    path: "webportal/signup",
    loadChildren: () =>
      import("./webportal/signup/signup.module").then(
        (m) => m.SignupPageModule
      ),
  },
  {
    path: "ionic",
    loadChildren: () =>
      import("./webportal/ionic/ionic.module").then((m) => m.IonicPageModule),
  },
  {
    path: "serviceItems",
    loadChildren: () =>
      import("./pages/service-item/list/list.module").then(
        (m) => m.ListPageModule
      ),
    canActivate: [SilverGuard],
  },
 
  {
    path: 'timecard',
    loadChildren: () => import('./pages/timecard/timecard.module').then( m => m.TimecardPageModule)
  },
  {
    path: "timesheet",
    loadChildren: () =>
      import("./pages/timesheet/timesheet.module").then(
        (m) => m.TimesheetPageModule
      ),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/modals/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: "webportal/thankyou/:token",
    loadChildren: () =>
      import("./webportal/thankyou/thankyou.module").then(
        (m) => m.ThankyouPageModule
      ),
  },
  {
    path: "webportal/signup/step2/:token",
    loadChildren: () =>
      import("./webportal/subscription-plan/subscription-plan.module").then(
        (m) => m.SubscriptionPlanPageModule
      ),
  },
  {
    path: 'admin/customer-mobile-import',
    loadChildren: () => import('./pages/admin/customer-mobile-import/customer-mobile-import.module').then( m => m.CustomerMobileImportPageModule)
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
