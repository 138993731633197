import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { errorType } from "src/app/types/formType";

@Component({
  selector: "app-input-text",
  templateUrl: "./input-text.component.html",
  styleUrls: ["./input-text.component.scss"],
})
export class InputTextComponent implements OnInit {
  @Input() name: string = "";
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() value: string | number;
  @Input() isSubmitted?: boolean = false;
  @Input() error: errorType;
  @Input() type: string = "text";
  @Input() formControlName: string = "";
  @Input() placeholder: string = "";
  @Input() extra: string = "";
  @Input() uppercase?: boolean = true;
  @Output() childBlur = new EventEmitter();
  @Output() focusEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onBlur(ev){
    //Sent to Parent
    this.childBlur.emit(ev)
  }
  onFocus(){
    //Sent to Parent
    this.focusEvent.emit(true)
  }
}
