import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, LoadingController } from "@ionic/angular";
import { AdminService } from "src/app/services/admin.service";
// import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-task",
  templateUrl: "./task.page.html",
  styleUrls: ["./task.page.scss"],
})
export class TaskPage implements OnInit {
  jobid;
  taskDesc;
  taskId;
  editordata: any = {};
  workOrderTasks: any = [];
  id: string = "";

  public action: string = "";

  constructor(
    public navParams: NavParams,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private db: AdminService
  ) {
    this.id = this.navParams.get("id");

    if (this.id == "create") {
      this.action = "Add";
    } else {
      this.action = "Edit";
    }
  }

  ngOnInit() {
    this.jobid = this.navParams.get("jobId");
    this.taskId = this.navParams.get("id");

    let woObsrv = this.db.getJob(this.jobid);

    woObsrv.subscribe((data) => {
      this.workOrderTasks = data.data["job"]["tasks"];
      this.workOrderTasks.filter((item) => {
        if (item.id == this.taskId) {
          this.taskDesc = item.description;
        }
      });
    });
    // this.jobid= this.navParams.get('description');
  }

  updateTask() {
    this.editordata.job = this.jobid;
    this.editordata.status = "active";
    this.editordata.description = this.taskDesc;

    this.db.updateJobTask(this.taskId, this.editordata).subscribe((res) => {
    
      this.closeModal();
    });
  }
  async saveTask() {
    const loader = await this.loadingCtrl.create({message:"Saving..."})
    loader.present();
    this.editordata.description = this.taskDesc;
    this.editordata.status = "active";
    this.editordata.job = this.jobid;
    this.db.createJobTask(this.jobid, this.editordata).subscribe(
      (res) => {
        this.modalCtrl.dismiss(res["data"]);
        this.loadingCtrl.dismiss();
      },
      (error) => {
        this.loadingCtrl.dismiss();
      }
    );
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
