import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalCreateCustomerPageRoutingModule } from './modal-create-customer-routing.module';

import { ModalCreateCustomerPage } from './modal-create-customer.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalCreateCustomerPageRoutingModule
  ],
  declarations: [ModalCreateCustomerPage]
})
export class ModalCreateCustomerPageModule {}
