import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ModalController,
  AlertController,
  LoadingController,
} from "@ionic/angular";
import { ModalAddItemsPage } from "../../modal-add-items/modal-add-items.page";
import { UserService } from "src/app/services/user.service";
import { AdminService } from "./../../../services/admin.service";
import { RouteService } from "src/app/services/route.service";
import { EditNotePage } from "../../modals/edit-note/edit-note.page";
import { CopymovePage } from "../modals/copymove/copymove.page";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.page.html",
  styleUrls: ["./detail.page.scss"],
})
export class DetailPage implements OnInit {
  isdisable;
  public jobs: any;
  userdata: any;
  public route: any;
  public company: any;
  public routeDetail: any = {
    name: "",
  };
  public sortedItems: any[] = [];
  isAdmin: boolean = false;
  loading: any = null;
  slidingItem: any;

  constructor(
    public router: Router,
    public ModalController: ModalController,
    public loadingCtrl: LoadingController,
    public ActivatedRoute: ActivatedRoute,
    public AdminService: AdminService,
    private routeService: RouteService,
    public user: UserService,
    private alertCtrl: AlertController,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.userdata = this.user.userInfo;
    this.route = this.ActivatedRoute.snapshot.params.id;
    this.isAdmin = this.user.userInfo.user.role == "admin" ? true : false;
  }

  ionViewWillEnter() {
    this.getItemList();
  }

  async ionRefresh(ev) {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create();
      this.loading.present();
    }
    setTimeout(() => {
      if (ev.type == "click") console.log(ev);
      else ev.target.complete();
      this.getItemList();
    }, 2000);
  }

  ionPull(route) {
    // console.log("ionPull Event Triggered!");
  }

  ionStart(route) {
    // console.log("ionStart Event Triggered!");
  }

  async getItemList() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create();
      this.loading.present();
    }
    const subscriber = this.AdminService.routeItems(this.route).subscribe(
      (res: any) => {
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
        //subscriber.unsubscribe();
        this.routeDetail = res.data["route"];
        let items = JSON.parse(JSON.stringify(this.routeDetail["items"]));
        this.sortedItems = JSON.parse(JSON.stringify(items.sort(this.AdminService.dynamicSort("order")) ));
      },
      (err) => {
        console.log(err);
        // subscriber.unsubscribe();
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
      }
    );
  }

  async createItem() {
    let i = this.sortedItems.length;
    const modal = await this.ModalController.create({
      component: ModalAddItemsPage,
      componentProps: { route: this.route, items: i },
    });
    modal.onDidDismiss().then((data) => {
      // this.sortedItems.push(data['data']['createRouteItem']);
      this.getItemList();
    });
    return await modal.present();
  }

  async showPopup(title, text) {
    let alert = await this.alertCtrl.create({
      message: title,
      subHeader: text,
      buttons: [
        {
          text: "OK",
        },
      ],
    });
    alert.present();
  }

  async copy(item, slidingItem: any) {
    this.slidingItem = slidingItem;
    let modal = await this.ModalController.create({
      component: CopymovePage,
      componentProps: {
        operation: "Copy",
        type: item.type,
        id: item.type,
        item,
        routeId: this.route,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.slidingItem.close();
      this.getItemList();
    });
    modal.present();
  }

  async move(item, slidingItem: any) {
    this.slidingItem = slidingItem;
    // this.AdminService.jobId = item.job.id;
    let modal = await this.ModalController.create({
      component: CopymovePage,
      componentProps: {
        operation: "Move",
        type: item.type,
        id: item.type,
        item,
        routeId: this.route,
      },
    });
    modal.onDidDismiss().then((data) => {
      this.slidingItem.close();
      this.getItemList();
    });
    modal.present();
  }

  async edit(item) {
    this.router.navigate(["/route/" + this.route + "/job/" + item.job.id]);
  }

  async editnote(item) {
    const modal = await this.ModalController.create({
      component: EditNotePage,
      componentProps: { route: this.route, itemdata: item },
    });
    modal.onDidDismiss().then((data) => {
      this.getItemList();
    });
    return await modal.present();
  }

  async deleteAlert(item, slidingItem: any) {
    //Check type
    this.slidingItem = slidingItem;
    this.confirmDeleteAlert(item);
  }

  async confirmDeleteAlert(item: any) {
    const itemType = item.type
    const itemCheck =  this.isLinked(item) 
    if ( itemCheck.hasPhotos || itemCheck.hasTime || itemCheck.hasServiceItems  ) {
      const alert = await this.alertCtrl.create({
        message: "Job has one or more clock-ins, service items, or photos  and cannot be deleted.",
        buttons: [
          {
            text: "ok",
            role: "cancel",
            handler: () => console.log("Cancel"),
          },
        ],
      });
      await alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        message: `Are you sure you want to delete this ${itemType}?`,
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => this.slidingItem.close(),
          },
          {
            text: "Agree",
            cssClass: "secondary",
            handler: () => this.deleteItem(item),
          },
        ],
      });
      await alert.present();
    }
  }

  async deleteItem(item) {
    try {
      this.routeService.deleteRouteItem(item.id).subscribe((res) => {
        this.slidingItem.close();
        this.getItemList();
      });
    } catch (e) {
      this.slidingItem.close();
      alert("Delete Failed!");
    }
  }

  navigate(item) {
    if (item.type == "job") {
      this.router.navigate(["/route/" + this.route + "/job/" + item.job.id]);
    } else this.openModal(); // open modal
  }

  openModal() {}

  doReorder(e: any) {
    //Get the new Order
    let draggedItem = this.sortedItems.splice(e.detail.from, 1)[0];
    this.sortedItems.splice(e.detail.to, 0, draggedItem);
    let mappedArray = this.sortedItems.map(this.idxMap);
    this.sortedItems = this.sortedItems.sort(
      this.AdminService.dynamicSort("order")
    );
    mappedArray.forEach((row) => {
      this.editByKey(this.sortedItems, "id", row.id, "order", row.order);
    });
    e.detail.complete();
    this.routeService.updateRouteItemsOrder(mappedArray).subscribe((res) => {
      this.getItemList();
    });
  }

  idxMap(e, index) {
    return { id: e.id, order: (index + 1) * 10 };
  }

  editByKey(myObj, key, value, editKey, editValue) {
    //Find index of specific object using findIndex method.
    let objIndex = myObj.findIndex((obj) => obj[key] == value);
    myObj[objIndex][editKey] = editValue;
  }

  isLinked(item){
    let obj = {
      hasTime: false,
      hasTasks: false,
      hasServiceItems: false,
      hasPhotos: false
    }
    if(item.job?.photos && item.job.photos.length > 0){
      obj.hasTime = true;
    }
    if(item.job?.jobTime && item.job.jobTime.length > 0 ){
      obj.hasTime = true;
    }
   if(item.job?.tasks && item.job.tasks.length > 0){
    obj.hasTime = true;
   }
   if(item.job?.photos && item.job.photos.length > 0){
    obj.hasTime = true;
   }
   if(item.job?.jobNotes && item.job.jobNotes.length > 0 ){
    obj.hasTime = true;
   } 
    return obj
  }

}
