import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalAddItemsPage } from 'src/app/pages/modal-add-items/modal-add-items.page';
import { EditEntryPage } from 'src/app/pages/timecard/edit-entry/edit-entry.page';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'app-time-card-edit-item',
  templateUrl: './time-card-edit-item.component.html',
  styleUrls: ['./time-card-edit-item.component.scss'],
})
export class TimeCardEditItemComponent implements OnInit {
  @Input() timecard: any
  @Output() dataUpdated: any = new EventEmitter<string>();
  showDetails: boolean =  false;
    constructor(private modalController: ModalController) { }
  
    ngOnInit() {}
    totalHours(totalTime){
      return totalTime / 60
    }
    appendDay(onDate: Date , entryDate: Date){
      const d1 = new Date(onDate)
      const d2 = new Date(entryDate)
      const month = d2.toLocaleString('default', { month: 'short' });
      const day = d2.getDate().toString()
      if(d1.getDate() !=  d2.getDate()){
        return `(${month} ${day})`
      }
    }

    async editEntry(item: any){
      let editModal = await this.modalController.create(
        { component: EditEntryPage,
          componentProps:{ entry: item}
      
        } )
        editModal.onDidDismiss().then( (data:any) => {
          console.log('Closed Edit Entry');
          this.dataUpdated.emit('refresh');
        });
        editModal.present()
    }

    async openMap(entry: any){
      const url ='https://www.google.com/maps/?q=' + entry.lat   + ',' + entry.lng
      await Browser.open({ url});
      
    }
  
  }