import { Injectable } from "@angular/core";
import { Preferences } from "@capacitor/preferences";

import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { ApolloQueryResult } from "apollo-client";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public isLoggedIn: boolean = false;
  public token: string = "";
  public ezbzId: string = "";
  public userInfo: any = {
    token: "",
    login: "",
    ezbzId: "",
    userId: "",
    companyId: "",
    user: {
      role: "",
      company: {
        id: "",
        ezbzId: "",
        planLevel: "",
      },
    },
  };
  public current: any = {
    jobId: "",
    routeId: "",
  };
  constructor(public apollo: Apollo) {}

  public signIn(payload: any) {
    let variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($data: loginInput!) {
          companyUserLogin(data: $data) {
            status
            token
            user {
              username
              id
              firstName
              lastName
              role
              company {
                id
                name
                ezbzId
                planSubscriptionId
                planLevel
              }
            }
          }
        }
      `,
      variables,
    }).valueChanges;
  }

  public signup(payload: any) {
    let variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($data: loginInput!) {
          companyUserLogin(data: $data) {
            status
            token
            user {
              username
              id
              firstName
              lastName
              role
              company {
                id
                name
                ezbzId
              }
            }
          }
        }
      `,
      variables,
    }).valueChanges;
  }

  public sendForgotPassword(payload: any) {
    let variables = {
      data: {
        ...payload,
      },
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($data: ForgotPasswordInput!) {
          sendForgotPassword(data: $data) {
            result
            msg
          }
        }
      `,
      variables,
    }).valueChanges;
  }

  async initFromStorage() {

    let ret = await Preferences.get({ key: "companyUserLogin" });
    try {
      let userStore = JSON.parse(ret.value);

      if (userStore) {
        this.isLoggedIn = true;
        this.userInfo = userStore;
      } else {
        this.isLoggedIn = false;
      }
      this.finalizeInit();
    } catch (e) {
      this.finalizeInit();
    }

    // });
  }
  finalizeInit() {
    let initComplete = new Event("initComplete");

    window.dispatchEvent(initComplete);
  }
  getProfile(id) {
    let variables = {
      id: id,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          company(id: $id) {
            id
            name
            status
            logo
            address
            address2
            addressCity
            addressState
            addressZip
            website
          }
        }
      `,
      variables,
    }).valueChanges;
  }
  async setCompanyInfo(){
    this.getProfile(this.userInfo.company.id).subscribe( res =>{
      const company = res.data['company']
      this.userInfo.company = company;
      Preferences.set({key:"companyUserLogin", value: this.userInfo})
    })

  }
}
