import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.scss"],
})
export class AccordionComponent implements OnInit {
  isMenuOpen = false;
  name = "test accordion";
  description = "This is a description";
  @Input() item: any;
  // @Input() domClass: string;

  constructor() {}

  ngOnInit() {}

  toggleAccordion() {}
}
