import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController, LoadingController } from "@ionic/angular";
// import { AngularEditorConfig } from "@kolkov/angular-editor";
import { JobService } from "src/app/services/job.service";
import { NgForm } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { ServiceItemService } from "src/app/services/serviceItem.service";

@Component({
  selector: "app-add-service-item",
  templateUrl: "./add-service-item.page.html",
  styleUrls: ["./add-service-item.page.scss"],
})
export class AddServiceItemPage implements OnInit {
  companyId = "";
  jobId = "";
  serviceAllItems: any[] = [];
  jobServiceItems: any[] = [];

  constructor(
    public navParam: NavParams,
    public modalCtrl: ModalController,
    private loadingController: LoadingController,
    public jobSvc: JobService,
    public userService: UserService,
    private serviceItemService: ServiceItemService
  ) {}

  ngOnInit() {
    this.companyId = this.userService.userInfo.user.company.id;
    this.jobId = this.navParam.get("jobId");
    this.serviceItemService.getServiceItems(this.companyId).subscribe((res) => {
      const serviceAllItems = res.data["getServiceItems"];
      this.jobServiceItems = this.navParam.get("jobServiceItems");
      this.serviceAllItems = serviceAllItems.map((item) => {
        let newItem = {...item}
        if (
          this.jobServiceItems.some(
            (jobItem) => jobItem.serviceItem["id"] === newItem["id"]
          )
        ) {
          newItem.isChecked = true;
          newItem.jobServiceItemId = this.jobServiceItems.find(
            (ele) => ele.serviceItem.id === item.id
          ).id;
        } else {
          newItem.isChecked = false;
          newItem.jobServiceItemId = "";
        }
        return newItem;
      });
     
    });
  }

  async addJobServiceItem(form: NgForm) {
    const lc = await this.loadingController.create({message:"saving..."})
        lc.present();
    [...this.serviceAllItems].map((item) => {
      if (!item.jobServiceItemId && item.isChecked) {
        delete item.isChecked;
        delete item.jobServiceItemId;
        
        this.jobSvc
          .createJobServiceItem(this.jobId, item.id, item.company.id)
          .subscribe((res) => {
          
          });
      } else if (!!item.jobServiceItemId && item.isChecked) {
        delete item.isChecked;
        this.jobSvc
          .updateJobServiceItem(
            this.jobId,
            item.id,
            item.company.id,
            item.jobServiceItemId
          )
          .subscribe((res) => {
           
          });
      } else if (item.jobServiceItemId && !item.isChecked) {
        this.jobSvc
          .deleteJobServiceItem(item.jobServiceItemId)
          .subscribe((res) => {
           
          });
      }
    });
    setTimeout(() => {
      lc.dismiss();
      this.dismiss();
    }, 1500);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
