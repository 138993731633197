import { NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
//import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
//import { InMemoryCache } from "apollo-cache-inmemory";
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';


import { environment } from "../environments/environment";
import { AddRoutePageModule } from "./pages/add-route/add-route.module";
import { AddUserPageModule } from "./pages/users/add-user/add-user.module";
import { ModalAddJobsPageModule } from "./pages/modal-add-jobs/modal-add-jobs.module";
import { ModalAddItemsPageModule } from "./pages/modal-add-items/modal-add-items.module";
import { ConfirmationPageModule } from "./pages/modals/confirmation/confirmation.module";
// import { AddCustomerPageModule } from './pages/customers/add-customer/add-customer.module';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EditPageModule } from "./pages/route/edit/edit.module";
import { ModalCreateCustomerPageModule } from "./pages/modal-create-customer/modal-create-customer.module";
import { DetailPageModule } from "./pages/route/detail/detail.module";
import { TaskPageModule } from "./pages/job/modals/task/task.module";
import { ComponentsModule } from "./components/components.module";
import { EditCustomerPageModule } from "./pages/customers/edit-customer/edit-customer.module";
import { EditNotePageModule } from "./pages/modals/edit-note/edit-note.module";
import { EditJobPageModule } from "./pages/job/modals/edit-job/edit-job.module";
import { CopymovePageModule } from "./pages/route/modals/copymove/copymove.module";
import { ForgotPasswordPageModule } from "./pages/modals/forgot-password/forgot-password.module";
import { AddServicePageModule } from "./pages/service-item/add-service/add-service.module";
import { AddServiceItemPageModule } from "./pages/job/modals/add-service-item/add-service-item.module";
import { AddRouteCustomerPageModule } from "./pages/modal-add-items/add-route-customer/add-route-customer.module";
import { CustomerMobileImportPipe } from './pages/admin/customer-mobile-import.pipe';

// import { AddCustomerPageModule } from './pages/customers/add-customer/add-customer.module';

@NgModule({
  declarations: [AppComponent, CustomerMobileImportPipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ApolloModule,
   // HttpLinkModule,
    HttpClientModule,
    AddRoutePageModule,
    AddServicePageModule,
    ModalAddJobsPageModule,
    ConfirmationPageModule,
    EditPageModule,
    EditJobPageModule,
    EditNotePageModule,
    ModalCreateCustomerPageModule,
    AddUserPageModule,
    ModalAddItemsPageModule,
    CopymovePageModule,
    DetailPageModule,
    TaskPageModule,
    ComponentsModule,
    AngularEditorModule,
    EditCustomerPageModule,
    ForgotPasswordPageModule,
    AddServiceItemPageModule,
    AddRouteCustomerPageModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    // AddCustomerPageModule,
   
  ],
  providers: [

    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LdX65MjAAAAACdGDJhpjzy1atsNsfyWY_WURNxr" },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphQL.serverUri,
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule {}
