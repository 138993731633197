import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { DateTime } from "luxon";

@Component({
  selector: 'app-edit-entry',
  templateUrl: './edit-entry.page.html',
  styleUrls: ['./edit-entry.page.scss'],
})
export class EditEntryPage implements OnInit {
  entryDetails: any
  timeIn: any
  timeOut: any
  constructor(
    private modalCtrl: ModalController,
    private timesheetService: TimesheetService,
    private navParams: NavParams
    
  ) { }

  ngOnInit() {
     
    this.entryDetails = this.navParams.get("entry")
    const ti = DateTime.fromISO(this.entryDetails.timeIn) 
    const to = DateTime.fromISO(this.entryDetails.timeOut) 
    this.timeIn =  this.cDate(ti.toISO())
    this.timeOut = this.cDate(to.toISO())
    
  }
  updateEntry(data){
    
    const timeIn = DateTime.fromISO(this.timeIn.replace(' ','T'))
    const timeOut = DateTime.fromISO(this.timeOut.replace(' ','T'))
    this.timesheetService.updateTimeCardEntry({timeIn , timeOut}, this.entryDetails.id).subscribe(res=>{

      var data = res.data['updateTimeCardEntry']
      this.closeModal(data)
    })
  }
  closeModal(data) {
    this.modalCtrl.dismiss(data);
  }
  dismiss(){
    this.modalCtrl.dismiss()
  }
  cDate(v: string): string{
    const parts = v.split("T")
    const dt = parts[0]
    const t = parts[1].split(".")
    return dt + ' ' + t[0]

  }
  updateValue(direction: string,ev){
    if(direction=='i')
    this.timeIn = ev.target.value

    if(direction=='o')
    this.timeOut = ev.target.value
    
  }
}
