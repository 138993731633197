import { Component, OnInit, Optional, Inject } from "@angular/core";
import { ModalController, NavParams, ToastController } from "@ionic/angular";
import { AdminService } from "src/app/services/admin.service";
import { UserService } from "../../../services/user.service";
import { RouteService } from "src/app/services/route.service";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.page.html",
  styleUrls: ["./edit.page.scss"],
})
export class EditPage implements OnInit {
  route: any = {};
  company: any = "";
  isRestrict: boolean = false;
  statusAlertOptions = {
    header: 'Route Status',
    subHeader: '',
    message: '',
    translucent: true,
  };
  constructor(
    private routeService: RouteService,
    private adminService: AdminService,
    private modalCtlr: ModalController,
    public navParams: NavParams,
    public userSvc: UserService,
    public toastController: ToastController
  ) {}

  ngOnInit() {
   
    this.route = this.navParams.data.route;
    const activeRoutes = this.navParams.data.activeRoutes;
    this.company = this.userSvc.userInfo["user"]["company"]["id"];
    const planLevel =
      this.userSvc.userInfo["user"]["company"]["planLevel"] || 10;
    this.isRestrict = this.adminService.checkRestrictRouteAndUser(
      activeRoutes,
      planLevel
    );
    if (this.isRestrict)
      this.statusAlertOptions.message= "Please upgrade your company plan to allow for more active routes."
    
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
    });
    toast.present();
  }

  editRoute(form) {
    //this.closeModal(this.route)
    let data = form.value;
    data["company"] = this.company;
    data["isTemplate"] = true;

    //data['id'] = this.route['id']

    this.routeService.updateRoute(this.route["id"], data).subscribe((res) => {
      var data = res.data["updateRoute"];
      this.modalCtlr.dismiss(data.result);
      //this.closeModal(data)
    });
  }
  closeModal() {
    this.modalCtlr.dismiss();
  }
}
