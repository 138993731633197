import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { TaskPageRoutingModule } from "./task-routing.module";
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { TaskPage } from "./task.page";
import { ComponentsModule } from "src/app/components/components.module";
import {toolbarConfig} from '../../../../../toolbar.config'
import { QuillModule } from "ngx-quill";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TaskPageRoutingModule,
    // AngularEditorModule,
    ComponentsModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar:toolbarConfig
      },
    }),
  ],

  declarations: [TaskPage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TaskPageModule {}
