import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
@Injectable({
  providedIn: "root",
})
export class TimesheetService {
  constructor(private apollo: Apollo) {}

  createTimeCard(data) {
    let variables = {
      data: {
        ...data,
      },
      console,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: TimeCardInput!) {
          createTimeCard(data: $data) {
            id
            onDate
            status
            totalTime
          }
        }
      `,
      variables: { data },
    });
  }

  userTimeCard(id, companyId) {
    const vars = { id, companyId };
    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!, $companyId: String) {
          userTimeCard(id: $id, companyId: $companyId) {
            id
            onDate
            status
            totalTime
          }
        }
      `,
      variables: { ...vars },
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  createTimeCardEntry(data) {
    let variables = {
      data: {
        ...data,
      },
      console,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: TimeCardEntryInput!) {
          createTimeCardEntry(data: $data) {
            id
          }
        }
      `,
      variables: { data },
    });
  }
  updateTimeCardEntry(data:any , id: String) {
   
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: TimeCardEntryUpdateInput!, $id: String) {
          updateTimeCardEntry(data: $data, id: $id) {
            id
          }
        }
      `,
      variables: { data , id},
    });
  }
  userTimeCardEntries(id) {
    const vars = { id };
    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          timeCardEntries(id: $id) {
            id
            onDate
            timeIn timeOut totalTime inStatus outStatus lat lng
          }
        }
      `,
      variables: { ...vars },
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getTotalTimeCards(id, companyId) {
    const vars = { id, companyId };
    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!, $companyId: String) {
          getTotalTimeCards(id: $id, companyId: $companyId) {
            id
            onDate
            status
            totalTime
          }
        }
      `,
      variables: { ...vars },
      fetchPolicy: "network-only",
    }).valueChanges;
  }
}
