import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { ApolloQueryResult } from "apollo-client";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  users: Observable<any>;
  customers: any[];
  routes: any[];
  jobId: any = " ";
  constructor(public apollo: Apollo) {}

  checkRestrictRouteAndUser(items: Array<any>, planLevel: Number) {
    let isRestricted = false;
    const activeItems = items.filter( itm => itm.status.toLowerCase() == 'active')
    switch (planLevel) {
      case 10:
        if (activeItems.length >= 1) isRestricted = true;
        break;
      case 20:
        if (activeItems.length >= 1) isRestricted = true;
        break;
      case 30:
        if (activeItems.length >= 2) isRestricted = true;
        break;  
      default:
        break;
    }
    return isRestricted;
  }

  createUser(user) {
    delete user["confirmPassword"];
    delete user["role"];
    // delete user["company"]
    let variables = {
      data: {
        ...user,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CreateCompanyUserInput) {
          createCompanyUser(data: $data) {
            id
            username
            firstName
            lastName
            email
            password
            role
            company {
              id
              name
            }
            status
          }
        }
      `,
      variables,
    });
  }

  getUsers(companyId: String) {
    let variables = {
      id: companyId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          company(id: $id) {
            id
            name
            companyUsers {
              id
              username
              firstName
              lastName
              mobile
              password
              role
              email
              status
            }
            customers {
              id
              lastName
              firstName
              address1
              address1
              city
              state
              zip
              displayName
              mobile
              address1
              address2
              city
              state
              zip
              email
              password
              status
              note
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getActiveUsers(companyId: String) {
    let variables = {
      id: companyId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          companyActiveUsers(companyId: $id) {
            id
            username
            firstName
            lastName
            password
            role
            email
            status
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  checkUserName(
    companyId: String,
    username: String
  ): Observable<ApolloQueryResult<any[]>> {
    let variables = {
      companyId,
      username,
    };

    return <any>this.apollo.watchQuery({
      query: gql`
        query ($companyId: String!, $username: String!) {
          checkUserName(companyId: $companyId, username: $username) {
            id
            username
            company {
              id
              ezbzId
              name
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  deleteUser(id) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteCompanyCustomer(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }

  deleteCompanyUser(id) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteCompanyUser(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateCompany(data) {
    delete data.phone;
    let variables = {
      data: {
        ...data,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: UpdateCompanyInput!) {
          updateCompany(data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateJob(jobid, data: any) {
    let variables = {
      id: jobid,
      data: {
        ...data,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: JobInput) {
          updateJob(id: $id, data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateCompanyUser(userid, data: any) {
    delete data["confirmPassword"];
    delete data["id"];
    delete data["__typename"];
    let variables = {
      id: userid,
      data: {
        ...data,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: CompanyUserInput) {
          updateCompanyUser(id: $id, data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateJobTask(jobTaskId, jobdata: any) {
    let variables = {
      jobTaskId: jobTaskId,
      data: {
        ...jobdata,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($jobTaskId: String, $data: JobTaskInput) {
          updateJobTask(jobTaskId: $jobTaskId, data: $data) {
            id
            job {
              id
            }
          }
        }
      `,
      variables,
    });
  }

  createJobTask(jobid, jobdata: any) {
    let variables = {
      jobId: jobid,
      data: {
        ...jobdata,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($jobId: String, $data: JobTaskInput) {
          createJobTask(jobId: $jobId, data: $data) {
            id
            job {
              id
              tasks {
                id
                status
                description
              }
            }
          }
        }
      `,
      variables,
    });
  }

  deleteJobTask(id) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteJobTask(id: $id) {
            id
            job {
              id
            }
          }
        }
      `,
      variables,
    });
  }

  updateJobNote(jobNoteId: string, jobdata: any) {
    // delete jobdata["description"];

    let variables = {
      jobNoteId: jobNoteId,
      data: {
        ...jobdata,
      },
    };

    return <any>this.apollo.mutate({
      mutation: gql`
        mutation ($jobNoteId: String!, $data: JobNoteInput!) {
          updateJobNote(jobNoteId: $jobNoteId, data: $data) {
            id
            job {
              id
              notes
            }
            status
            description
          }
        }
      `,
      variables,
    });
  }

  createJobNote(jobid, jobdata: any) {
    let variables = {
      jobId: jobid,
      data: {
        ...jobdata,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($jobId: String, $data: JobNoteInput) {
          createJobNote(jobId: $jobId, data: $data) {
            id
            job {
              id
              notes
              jobNotes {
                id
                description
                status
              }
            }
          }
        }
      `,
      variables,
    });
  }

  deleteJobNote(id) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteJobNote(id: $id) {
            id
            job {
              id
            }
          }
        }
      `,
      variables,
    });
  }

  //*** JOB HOURS */

  createJobTime(jobid, jobdata: any) {
    let variables = {
      jobId: jobid,
      data: {
        ...jobdata,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($jobId: String, $data: JobTimeInput) {
          createJobTime(jobId: $jobId, data: $data) {
            id
            job {
              id
            }

            user {
              username
            }
            timeIn
          }
        }
      `,
      variables,
      // fetchPolicy:'network-only'
    });
  }

  uploadImage(image: any) {
    let variables = {
      image: {
        ...image,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($image: uploadImageInput) {
          uploadImage(image: $image) {
            status
            url
            id
          }
        }
      `,
      variables,
    });
  }

  createJobPhoto(jobid, data: any) {
    let variables = {
      jobId: jobid,
      data: {
        ...data,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($jobId: String, $data: JobPhotoInput) {
          createJobPhoto(jobId: $jobId, data: $data) {
            id
            job {
              id
              photos {
                id
                notes
                url
              }
            }
            notes
            url
          }
        }
      `,
      variables,
    });
  }

  createJob(data, route, company) {
    let job = data;
    job["route"] = route;
    job["company"] = company;
    let variables = {
      data: {
        ...job,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: JobInput) {
          createJob(data: $data) {
            id
            route {
              id
            }
            customer {
              id
            }
            company {
              id
            }
          }
        }
      `,
      variables,
    });
  }

  deletejobPhoto(id: any) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteJobPhoto(id: $id) {
            id
            notes
            url
          }
        }
      `,
      variables,
    });
  }

  deleteJobTime(id: any) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteJobTime(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }

  routeItems(routId) {
    let variables = {
      id: routId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String) {
          route(id: $id) {
            id
            name
            items {
              id
              type
              order
              job {
                id
                status
                notes
                customer {
                  displayName
                  id
                  firstName
                  lastName
                  address1
                  city
                  state
                  zip
                }
                tasks {
                  id
                }
                jobTime {
                  id
                }
                photos {
                  id
                }
                jobNotes {
                  id
                }
                jobServiceItems{
                  id
                }
              }

              note {
                id
                note
              }
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  public dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        parseInt(a[property]) < parseInt(b[property])
          ? -1
          : a[property] > b[property]
          ? 1
          : 0;
      return result * sortOrder;
    };
  }

  public getJob(jobId: String) {
    let variables = {
      id: jobId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          job(id: $id) {
            id
            notes
            status
            route {
              id
              name
              status
            }
            jobNotes {
              id
              description
              status
              createByName
            }
            tasks {
              id
              description
              status
            }
            customer {
              id
              lastName
              displayName
              address1
              city
              state
              zip
            }
            photos {
              id
              notes
              url
              photoDate
            }
            jobTime {
              status
              timeIn
              timeInAt
              timeOut
              timeOutAt
              id
              user {
                username
                firstName
                lastName
                id
              }
            }
            jobServiceItems {
              id
              isComplete
              serviceItem {
                id
                name
              }
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  public deleteJob(jobid) {
    let variables = {
      id: jobid,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteJob(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }

  getSettings(companyId) {
    let variables = {
      companyId: companyId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($companyId: String, $status: String) {
          settings(companyId: $companyId, status: $status) {
            id
            name
            status
          }
        }
      `,
      variables,
    }).valueChanges;
  }

  //Get Import History
  customerImports(companyId) {
    let variables = {
      companyId: companyId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($companyId: String) {
          customerImports(companyId: $companyId) {
            createdAt
            desc
            recordCount
            status
            invalidData
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }
}
