import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { EditJobPageRoutingModule } from "./edit-job-routing.module";

import { EditJobPage } from "./edit-job.page";
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { QuillModule } from "ngx-quill";
import {toolbarConfig} from '../../../../../toolbar.config'
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditJobPageRoutingModule,
    // AngularEditorModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: toolbarConfig

      },
    }),
  ],
  declarations: [EditJobPage],
})
export class EditJobPageModule {}
