import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";
import { UserService } from "src/app/services/user.service";
import { RouteService } from "src/app/services/route.service";
import { AdminService } from "src/app/services/admin.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-copymove",
  templateUrl: "./copymove.page.html",
  styleUrls: ["./copymove.page.scss"],
})
export class CopymovePage implements OnInit {
  operation: string;
  type: string;
  id: string;
  item: any;
  routes: any = [];
  routeId: string;
  // currentRouteId: string;
  jobId: any
  constructor(
    private navParams: NavParams,
    private routeService: RouteService,
    private modal: ModalController,
    private user: UserService,
    private db: AdminService
  ) {
    this.operation = this.navParams.get("operation");
    this.type = this.navParams.get("type");
    this.id = this.navParams.get("id");
    this.item = this.navParams.get("item");
    this.routeId = this.navParams.get("routeId");
  }

  ngOnInit() {
    this.routeService
      .getRouters(this.user.userInfo.user.company.id)
      .subscribe((res) => {
        this.routes = res.data["routes"].filter((r) =>
          r.status.toLowerCase() == 'active' || r.status.toLowerCase() == 'draft'
        );
      });
  }

  save() {
    if (this.operation == "Copy") {
      this.copy();
    } else {
      this.move();
    }
  }

  move() {
    // let oldRouteId = this.currentRouteId;
    this.routeService
      .moveRouteItem(this.item.id, this.routeId)
      .subscribe((res) => {
     
        this.modal.dismiss();
        this.UpdateJob();
      });
  }

  UpdateJob() {
    let data: any = {};
    data.route = this.routeId;
    data.customer = this.item["job"]["customer"]["id"];
    data.company = this.user.userInfo.user.company.id;
    data.status = "active";
   
    this.db.updateJob(this.jobId, data).subscribe((res) => {
      // console.log(res);
    });
  }

  copy() {
    this.routeService
      .copyRouteItem(this.item.id, this.routeId)
      .subscribe((res) => {
      
        this.modal.dismiss();
       
      });
  
  }

  dismiss() {
    this.modal.dismiss();
  }
}
