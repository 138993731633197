import { AdminService } from "../../../services/admin.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Validators, FormBuilder, NgForm } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { Router } from "@angular/router";
import {
  NavController,
  ModalController,
  NavParams,
  ToastController,
} from "@ionic/angular";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.page.html",
  styleUrls: ["./add-user.page.scss"],
})
export class AddUserPage implements OnInit {
  user: User = <User>{};
  edituser: any = { status: "active", role: "regular" };
  isUpdated: boolean = false;
  showUpdatePassword = false;
  isRestrict: boolean = false;
  roleAlertOptions = {
    header: 'Role',
    subHeader: '',
    message: 'Choose only one',
    translucent: true,
  };
   isRestrictMessage ="Please upgrade plan to allow for more admin users."
  companyid;
  errMsg;
  //@ViewChild("userForm", null) userForm: NgForm;

  constructor(
    private adminService: AdminService,
    private userService: UserService,
    private route: Router,
    public toastController: ToastController,
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.companyid = this.userService.userInfo.user.company.id;
    this.edituser = this.navParams.get("user");
    const adminUsers = this.navParams.get("adminUsers");
    const planLevel = this.userService.userInfo.user.company.planLevel || 10;
   
    this.isRestrict = this.adminService.checkRestrictRouteAndUser(
      adminUsers,
      planLevel
    );

    if (this.edituser != undefined) {
      this.isUpdated = true;
      this.edituser.status = this.edituser.status.toLowerCase();
      if (this.isRestrict)
        this.setRoleAlertOptions();
    } else {
      this.edituser = { status: "active", role: "regular" };
      if (this.isRestrict)
       this.setRoleAlertOptions();
    }
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
    });
    toast.present();
  }

  updateuser(data: NgForm) {
    let id = this.edituser.id;
    data.value.status = "active";
    if (this.isUpdated && this.showUpdatePassword) {
      let passwordOk = this.checkPasswords(data);
      if (!passwordOk) {
        this.errMsg = "Password Error: Must Match and at least 6 characters.";
        return;
      }
    }
    this.adminService.updateCompanyUser(id, data.value).subscribe((res) => {
      this.closeModal(res);
    });
  }
  setRoleAlertOptions(){
    this.roleAlertOptions = {
      header: 'Role',
      subHeader: this.isRestrictMessage,
      message: 'Choose only one',
      translucent: true,
    };
  }

  addUser(data) {
    this.errMsg = "";
    let obj = data.value;
    obj.status = "active";
    let d = new Date();
    const emf = d.getTime() / 1000;
    obj["email"] = obj["email"] == "" ? `ez${emf}@ezbzuser.com` : obj["email"];
    obj["company"] = this.companyid;
    obj["role"] = this.edituser.role ? this.edituser.role : "regular";

    this.adminService
      .checkUserName(obj.company, obj.username)
      .subscribe((res) => {
        const responseData = res["data"]["checkUserName"];
        if (responseData.length === 0) {
          this.adminService.createUser(data.value).subscribe(
            (res) => {
              this.closeModal(res.data["createCompanyUser"]);
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          this.errMsg = "Username is already exist.";
        }
      });
  }
  closeModal(data) {
    this.modalCtrl.dismiss(data);
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }
  checkPasswords(f: NgForm): boolean {
    if (this.isUpdated && f.form.value["password"].length == 0) {
      return true;
    }
    return f.form.value["password"].length > 5 &&
      f.form.value["password"] === f.form.value["confirmPassword"]
      ? true
      : false;
  }
}
export interface User {
  username: string;
  firstName: string;
  lastName: String;
  email: String;
  password: String;
  role: String;
  company: String;
  status: String;
}
