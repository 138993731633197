import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class Loginauthservice implements CanActivate {
  constructor(private user: UserService,private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const currentPath = this.router.url;
      const isWebPortal = currentPath.includes('webportal');

      if(this.user.isLoggedIn || isWebPortal){
        return true
      }
      else{
        this.router.navigate(['/login'])
      }
    
  }
}
