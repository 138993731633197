import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

import { UserService } from "../../../services/user.service";
import { CustomerService } from "src/app/services/customer.service";

@Component({
  selector: "app-edit-customer",
  templateUrl: "./edit-customer.page.html",
  styleUrls: ["./edit-customer.page.scss"],
})
export class EditCustomerPage implements OnInit {
  customer: any = {};
  company: any = "";
  data: any = {};
  cusmerid;
  constructor(
    private modalCtlr: ModalController,
    private userSvc: UserService,
    private customerService: CustomerService,
    public navParams: NavParams
  ) {}

  ngOnInit() {
    this.customer = this.navParams.data;
    this.company = this.userSvc.userInfo["user"]["company"]["id"];
  }

  dismiss() {
    this.modalCtlr.dismiss();
  }

  editCustomer(form) {
    this.data = form.value;
    // return false;
    this.data.company = this.company;
    this.data.status = this.customer.status;
    this.cusmerid = this.customer.id;

    this.customerService
      .updateCustomer(this.cusmerid, this.data)
      .subscribe((res) => {
        var data = res.data["updateCustomer"];
        this.closeModal(data);
      });
  }

  closeModal(data = {}) {
    this.modalCtlr.dismiss();
  }
}
