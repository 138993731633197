import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { JobService } from "src/app/services/job.service";
import { NgForm } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "app-edit-job",
  templateUrl: "./edit-job.page.html",
  styleUrls: ["./edit-job.page.scss"],
})
export class EditJobPage implements OnInit {
  // editorConfig: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: "190px",
  //   minHeight: "0",
  //   maxHeight: "auto",
  //   width: "auto",
  //   minWidth: "0",
  //   translate: "yes",
  //   enableToolbar: true,
  //   showToolbar: true,
  //   placeholder: "Enter text here...",
  //   defaultParagraphSeparator: "",
  //   defaultFontName: "",
  //   defaultFontSize: "",
  //   fonts: [
  //     { class: "arial", name: "Arial" },
  //     { class: "calibri", name: "Calibri" },
  //     { class: "comic-sans-ms", name: "Comic Sans MS" },
  //   ],
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: "redText",
  //       class: "redText",
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ],
  //   uploadUrl: "v1/image",
  //   uploadWithCredentials: false,
  //   sanitize: true,
  //   toolbarPosition: "top",
  //   toolbarHiddenButtons: [
  //     [
  //       "subscript",
  //       "superscript",
  //       "redo",
  //       "italic",
  //       "justifyFull",
  //       ,
  //       "heading",
  //       "indent",
  //     ],
  //     ["fontSize", "customClasses", "insertVideo"],
  //   ],
  // };
  job: any = {};

  constructor(
    public Navparam: NavParams,
    public modalCtrl: ModalController,
    public jobSvc: JobService,
    public user: UserService
  ) {}

  ngOnInit() {
    this.job = this.Navparam.get("job");
  }

  editJob(form: NgForm) {
    let data = {
      route: this.job.route.id,
      customer: this.job.customer.id,
      company: this.user.userInfo.user.company.id,
      status: this.job.status,
      notes: this.job.notes,
    };

    this.jobSvc.updateJob(this.job.id, data).subscribe((res) => {
      this.dismiss();
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  typeSelected(event) {
    let type;
    type = event.target.value;
  }
}
