import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngx-editor',
  templateUrl: './ngx-editor.component.html',
  styleUrls: ['./ngx-editor.component.scss'],
})
export class NgxEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
