import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class RouteService {
  constructor(private apollo: Apollo) {}

  getRouters(companyId) {
    let variables = {
      companyId: companyId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($companyId: String, $status: String) {
          routes(companyId: $companyId, status: $status) {
            id
            isTemplate
            company {
              id
              name
            }
            name
            status
            jobs {
              id
              jobTime {
                id
              }
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }
  getRouteArchive(companyId) {
    let variables = {
      companyId: companyId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($companyId: String, $status: String) {
          routesArchive(companyId: $companyId, status: $status) {
            id
            isTemplate
            company {
              id
              name
            }
            name
            status
            jobs {
              id
              jobTime {
                id
              }
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }
  createRoute(route) {
    delete route["id"];
    delete route["modal"];
    delete route["__typename"];
    let variables = {
      data: {
        ...route,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: RouteInput) {
          createRoute(data: $data) {
            id
            company {
              id
            }
            name
            status
            isTemplate
          }
        }
      `,
      variables,
    });
  }
  copyRoute(routeId) {
    let variables = {
      routeId,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($routeId: String) {
          copyRoute(routeId: $routeId) {
            id
            company {
              id
            }
            name
            status
            isTemplate
          }
        }
      `,
      variables,
    });
  }

  deleteRoute(id) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteRoute(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateRoute(id, route) {
    delete route.id;
    delete route.__typename;
    delete route.jobs;
    let variables = {
      id: id,
      data: {
        ...route,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: RouteInput) {
          updateRoute(id: $id, data: $data) {
            id
            company {
              id
            }
            name
            status
            isTemplate
          }
        }
      `,
      variables,
    });
  }

  /*********** ROUTE ITEMS ***************/
  createRouteItem(data: any, job: any, note: any) {
    let variables = {
      data: {
        ...data,
      },
      job: {
        ...job,
      },
      note: {
        ...note,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation (
          $data: RouteItemInput
          $job: JobInput
          $note: RouteNoteInput
        ) {
          createRouteItem(data: $data, job: $job, note: $note) {
            id
            type
            job {
              customer {
                firstName
                lastName
                displayName
              }
            }
            order
          }
        }
      `,
      variables,
    });
  }

  updateRouteItemsOrder(itmMap: any) {
    let variables = {
      items: [...itmMap],
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($items: [routeOrderInput!]!) {
          updateRouteItemsOrder(items: $items) {
            status
          }
        }
      `,
      variables,
    });
  }

  updateJob(id, data: any) {
    let variables = {
      id: id,
      data: {
        ...data,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: JobInput) {
          updateJob(id: $id, data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }
  updateRouteNote(id, data: any) {
    let variables = {
      id: id,
      data,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: String) {
          updateRouteNote(id: $id, data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }
  deleteRouteItem(id) {
    let variables = {
      id: id,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          deleteRouteItem(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }

  moveRouteItem(oldRouteId, newRouteId) {
    let variables = {
      routeItemId: oldRouteId,
      newRouteId: newRouteId,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($routeItemId: String, $newRouteId: String) {
          moveRouteItem(routeItemId: $routeItemId, newRouteId: $newRouteId) {
            id
          }
        }
      `,
      variables,
    });
  }

  copyRouteItem(oldRouteId, newRouteId) {
    let variables = {
      routeItemId: oldRouteId,
      newRouteId: newRouteId,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($routeItemId: String, $newRouteId: String) {
          copyRouteItem(routeItemId: $routeItemId, newRouteId: $newRouteId) {
            id
          }
        }
      `,
      variables,
    });
  }
}
