let toolbarConfig =[
    ['bold', 'italic', 'underline', 'strike','link','image'], 
    [{ 'color': ['#000','#FFF','#0000FF','#FFFF00','#AAA','#FF0000','#00FF00'] }, { 'background': ['#000','#FFF','#0000FF','#FFFF00','#AAA','#FF0000','#00FF00'] }],       // toggled buttons
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
   
             // dropdown with defaults from theme
    [{ 'font': [] }],
  
    ['clean']                                         // remove formatting button
  ];

export  {toolbarConfig};
