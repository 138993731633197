import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.page.html',
  styleUrls: ['./confirmation.page.scss'],
})
export class ConfirmationPage implements OnInit {
  route:any={}
  company:""
  constructor(public navParams: NavParams,private AdminService: AdminService, private user: UserService,private modalCtrl: ModalController,) { 
    
  }

  ngOnInit() {

   this.company = this.user.userInfo['user']['company']['id']
   
    this.route = this.navParams.data
  
    //this.route.value['company']=this.company;
    //this.route['company']=this.company;
  }
  closeModal(data) {
    this.modalCtrl.dismiss(data);
  }
  dismiss(){
    this.modalCtrl.dismiss()
  }
  copy(route){

  }

}
