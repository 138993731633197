import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class SilverGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentPath = this.router.url;
    const isWebPortal = currentPath.includes("webportal");
    if (
      (this.userService.isLoggedIn || isWebPortal) &&
      //this.userService.userInfo.user.role === "admin" &&
      parseInt(this.userService.userInfo.user.company.planLevel) >= 30
    ) {
      return true;
    } else {
      this.router.navigate(["/route/list"]);
    }
  }
}
