import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-modal-add-jobs',
  templateUrl: './modal-add-jobs.page.html',
  styleUrls: ['./modal-add-jobs.page.scss'],
})
export class ModalAddJobsPage implements OnInit {
  route
  company
  custmers
  constructor(
    private modalCtrl: ModalController,
    private AdminService: AdminService,
    private user:UserService
  ) { }

  ngOnInit() {
    
      this.AdminService.getUsers(this.company).subscribe(res=>{
        this.custmers = res.data['company']['customers']
      })
    
  }
  addJob(data){
   
    this.AdminService.createJob(data.value,this.route,this.company).subscribe(res=>{

      var data = res.data['createRoute']
      this.closeModal(data)
    })
  }
  closeModal(data) {
    this.modalCtrl.dismiss(data);
  }
  dismiss(){
    this.modalCtrl.dismiss()
  }
}
