import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NavParams, ModalController } from '@ionic/angular';
import { RouteService } from 'src/app/services/route.service';
@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.page.html',
  styleUrls: ['./edit-note.page.scss'],
})
export class EditNotePage implements OnInit {
  item:any={}
  routeId:any;
  form;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '190px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [ 'subscript',
      'superscript','redo','italic','justifyFull',,
      'heading',
      'indent',],
      ['fontSize','customClasses','insertVideo']
    ]
}; 
  constructor(
    public Navparam:NavParams,
    private modalCtrl:ModalController,
    private routeService: RouteService,
  ) { }

  ngOnInit() {
    this.routeId=this.Navparam.get('route');
    this.item=this.Navparam.get('itemdata')
    this.form.note = this.item.note.note
  }
  dismiss(){
    this.modalCtrl.dismiss();
  }
  save(frm: any){
    
    this.routeService.updateRouteNote(this.item.note.id, this.item.note.note).subscribe(res=>{
      this.dismiss()
    })
    this.dismiss()
  }
}
