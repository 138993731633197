import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { AddRouteCustomerPageRoutingModule } from "./add-route-customer-routing.module";

import { AddRouteCustomerPage } from "./add-route-customer.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddRouteCustomerPageRoutingModule,
  ],
  declarations: [AddRouteCustomerPage],
})
export class AddRouteCustomerPageModule {}
