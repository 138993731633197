import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { errorType } from "src/app/types/formType";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnInit {
  @Input() name: string = "";
  @Input() title: string = "";
  @Input() value: any;
  @Input() error: errorType;
  @Input() formControlName: string = "";
  @Input() placeholder: string = "";
  @Input() extra: string = "";
  @Input() options: any = {};

  @Output() ngModelChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ionViewWillEnter() {}

  compareWithFn = (o1, o2) => {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  };

  onSelectChange(event) {
    this.ngModelChange.emit(event.target.value);
  }
}
