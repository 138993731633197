import { Injectable } from "@angular/core";

import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class JobService {
  constructor(private apollo: Apollo) {}

  getJob(jobId: String) {
    let variables = {
      id: jobId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          job(id: $id) {
            id
            notes
            status
            route {
              id
              name
              status
            }
            jobNotes {
              id
              description
              status
            }
            tasks {
              id
              description
              status
            }
            customer {
              id
              lastName
              displayName
              address1
              city
              state
              zip
            }
            photos {
              id
              notes
              url
            }
            jobTime {
              status
              timeIn
              timeOut
              id
              user {
                username
                firstName
                lastName
                id
              }
            }
            jobServiceItems {
              id
              note
              isComplete
              serviceItem {
                id
                name
                abbreviation
                status
              }
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  getJobServiceItems(jobId: String) {
    let variables = {
      id: jobId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          getJobServiceItemsByJobId(id: $id) {
            id
            note
            isComplete
            job {
              id
            }
            company {
              id
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }

  createJobServiceItem(
    jobId: String,
    serviceItemId: String,
    companyId: String
  ) {
    let variables = {
      jobId,
      serviceItemId,
      companyId,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation (
          $jobId: String!
          $serviceItemId: String!
          $companyId: String!
        ) {
          createJobServiceItem(
            jobId: $jobId
            serviceItemId: $serviceItemId
            companyId: $companyId
          ) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateJobServiceItem(
    jobId: String,
    serviceItemId: String,
    companyId: String,
    jobServiceItemId: String
  ) {
    let variables = {
      jobId,
      serviceItemId,
      companyId,
      jobServiceItemId,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation (
          $jobId: String!
          $serviceItemId: String!
          $companyId: String!
          $jobServiceItemId: String!
        ) {
          updateJobServiceItem(
            jobId: $jobId
            serviceItemId: $serviceItemId
            companyId: $companyId
            jobServiceItemId: $jobServiceItemId
          ) {
            id
          }
        }
      `,
      variables,
    });
  }

  deleteJobServiceItem(jobServiceItemId: String) {
    let variables = {
      jobServiceItemId,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($jobServiceItemId: String!) {
          deleteJobServiceItem(jobServiceItemId: $jobServiceItemId) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateJobServiceItemByPayload(id: String, payload) {
    let variables = {
      id,
      data: { ...payload },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String!, $data: UpdateJobServiceItemInput!) {
          updateJobServiceItemByPayload(id: $id, data: $data) {
            id
          }
        }
      `,
      variables,
    });
  }

  updateJob(jobId: String, data: any) {
    let variables = {
      id: jobId,
      data: data,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String, $data: JobInput) {
          updateJob(id: $id, data: $data) {
            id
            notes
            status
            route {
              id
              name
              status
            }
            jobNotes {
              id
              description
              status
            }
            tasks {
              id
              description
              status
            }
            customer {
              id
              lastName
              displayName
              address1
              city
              state
              zip
            }
            photos {
              id
              notes
              url
            }
            jobTime {
              status
              timeIn
              timeOut
              id
              user {
                username
                firstName
                lastName
                id
              }
            }
          }
        }
      `,
      variables,
    });
  }

  // -------   CHECK IN / OUT ------------ //
  updateJobTime(jobTimeId, jobdata: any) {
    let variables = {
      jobTimeId: jobTimeId,
      data: {
        ...jobdata,
      },
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($jobTimeId: String, $data: JobTimeInput) {
          updateJobTime(jobTimeId: $jobTimeId, data: $data) {
            id
            status
          }
        }
      `,
      variables,
    });
  }
}
