import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { EditNotePageRoutingModule } from "./edit-note-routing.module";
import {toolbarConfig} from '../../../../toolbar.config'
import { EditNotePage } from "./edit-note.page";
import { QuillModule } from "ngx-quill";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditNotePageRoutingModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: toolbarConfig
      },
    }),
  ],
  declarations: [EditNotePage],
})
export class EditNotePageModule {}
