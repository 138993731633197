import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { Apollo, Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private apollo: Apollo) {}
  customer(customerId: String) {
    let variables = {
      id: customerId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          companyCustomer(id: $id) {
            id
          
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }
  createCustomer(data) {
    let variables = {
      data: {
        ...data,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($data: CompanyCustomerInput) {
          createCompanyCustomer(data: $data) {
            id
            firstName
            lastName
            displayName
            address1
            address2
            city
            state
            zip
            email
            password
            status
          }
        }
      `,
      variables,
    });
  }

  updateCustomer(customerid, customer: any) {
    delete customer["id"];
    delete customer["__typename"];

    let variables = {
      id: customerid,
      data: {
        ...customer,
      },
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String!, $data: CompanyCustomerInput!) {
          updateCompanyCustomer(id: $id, data: $data) {
            id
            lastName
            displayName
            address1
            address2
            city
            state
            zip
            email
            password
            status
          }
        }
      `,
      variables,
    });
  }
  copyCompanyCustomer(id) {
    let variables = {
      id: id,
    };
    return this.apollo.mutate({
      mutation: gql`
        mutation ($id: String) {
          copyCompanyCustomer(id: $id) {
            id
          }
        }
      `,
      variables,
    });
  }
  companyCustomers(companyId: String) {
    let variables = {
      id: companyId,
    };

    return this.apollo.watchQuery({
      query: gql`
        query ($id: String!) {
          company(id: $id) {
            id
            name
            customers {
              id
              lastName
              firstName
              address1
              address1
              city
              state
              zip
              displayName
              mobile
              address1
              address2
              city
              state
              zip
              email
              password
              status
              note
            }
          }
        }
      `,
      variables,
      fetchPolicy: "network-only",
    }).valueChanges;
  }
  async importCustomerCSV(file: any, companyId: any) {
    //Upload to GraphQL
    const variables = {
      companyId,
      file,
    };

    return this.apollo.mutate({
      mutation: gql`
        mutation ($companyId: String!, $file: uploadImageInput!) {
          uploadCustomerCSV(companyId: $companyId, file: $file) {
            result
            msg
          }
        }
      `,
      variables,
    });
  }
}
/*   uploadCustomerCSV(companyId:"ck4x6j82700220735r4wuz7ta", file:{
    id: "10",
companyId: "ck4x6j82700220735r4wuz7ta",
objectType: "csv",
objectId: "file12022101",
imageEncoded: "bmFtZSxlbWFpbCxwaG9uZQpsZXZhcixsZXZhci5iZXJyeUBlZHJpdmVuZW50LmNvbSwiNTEzNDAzMTIxMCI=",
imagename: "file12012.csv",
  }){
    status desc
  }
  
  */
