import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {
  forgotForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading: boolean = false;

  constructor(
    private modalCtrl:ModalController,
    public formBuilder: FormBuilder,
    private userService: UserService,
    public toastController: ToastController
  ) { }

  ngOnInit() {
    this.setForms();
    // this.routeId=this.Navparam.get('route');
    // this.item=this.Navparam.get('itemdata')
    // this.form.note = this.item.note.note
  }

  setForms() {
    this.forgotForm = this.formBuilder.group(
      {
        company: new FormControl("", Validators.compose([Validators.required, Validators.minLength(3)])),
        email: new FormControl("", 
        Validators.compose([
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"),
          ],
        )),
      }
    );
  }

  get errorControl() {
    return this.forgotForm.controls;
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
  save(frm: any){

    this.isSubmitted = true;
    if (!this.forgotForm.valid) {
      return false;
    }
    this.isSubmitted = false;
    this.isLoading = true;
    this.userService.sendForgotPassword(this.forgotForm.value).subscribe(
      (res) => {
        const {result, msg} = res.data["sendForgotPassword"];
        if (result == "success") {
          this.isSubmitted = false;
          this.presentToast("We sent email. Please check your email.");
          this.dismiss()
        } else {
          this.presentToast(msg);
        }
      },
      (error) => {
        this.isSubmitted = false;
        console.log(error);
        this.presentToast("Failed Please try again.");
      }
    );
  }

}
