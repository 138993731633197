import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxEditorComponent } from "./ngx-editor/ngx-editor.component";
import { AccordionComponent } from "./accordion/accordion.component";
import { InputTextComponent } from "./input-text/input-text.component";
import { SelectComponent } from "./select/select.component";
import { TimeCardItemComponent } from "./time-card-item/time-card-item.component";
import { RecaptchaComponent } from "ng-recaptcha";
import { TimeCardEditItemComponent } from "./time-card-edit-item/time-card-edit-item.component";

@NgModule({
  declarations: [
    NgxEditorComponent,
    AccordionComponent,
    InputTextComponent,
    SelectComponent,
    TimeCardItemComponent,
    TimeCardEditItemComponent,
    
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    // Ng2SearchPipeModule
  ],
  exports: [AccordionComponent, InputTextComponent, SelectComponent, TimeCardItemComponent, TimeCardEditItemComponent  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule {}
