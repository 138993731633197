import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";
import { ModalAddItemsPageRoutingModule } from "./modal-add-items-routing.module";
import {toolbarConfig} from '../../../toolbar.config'
import { ModalAddItemsPage } from "./modal-add-items.page";
import { QuillModule } from "ngx-quill";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalAddItemsPageRoutingModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar:toolbarConfig
      },
    }),
  ],
  declarations: [ModalAddItemsPage],
})
export class ModalAddItemsPageModule {}
